import {Environment, Region} from '@coveo/platform-client';
import validator from 'validator';
import {Locales} from './strings';
import {sanitizeClassicHostedSearchPageName, sanitizeHostedSearchPageId, sanitizeOrganizationId} from './Sanitization';
import {RegionUtils} from './utils';

// eslint-disable-next-line no-underscore-dangle
declare const __APP_NAME__: string;
const localizedAppNameMap = {
    'commerce-hub': Locales.format('appName.commerceHub'),
    'knowledge-hub': Locales.format('appName.knowledgeHub'),
    'admin-ui': Locales.format('appName.adminUi'),
    docs: Locales.format('appName.docs'),
};
// eslint-disable-next-line no-underscore-dangle
export const LOCALIZED_APP_NAME = localizedAppNameMap[__APP_NAME__];

export enum DOC_HOSTS {
    DOCS = 'DOCS',
    COMMUNITY = 'COMMUNITY',
}

// Will be used by UA and SO to get the type of the config
export type Configuration = typeof Config;

export const ENVIRONMENT = {
    local: 'local',
    dev: 'dev',
    stg: 'stg',
    production: 'production',
    hipaa: 'hipaa',
};

const ENVIRONMENT_SHORT = {
    production: 'prd',
    hipaa: 'hip',
} as const;

export const CLOUD_PLATFORM_BASE_URL = {
    [ENVIRONMENT.local]: 'https://local.stratus.coveo.com:8481',
    [ENVIRONMENT.dev]: `https://platformdev${RegionUtils.placeholder}.cloud.coveo.com`,
    [ENVIRONMENT.stg]: `https://platformstg${RegionUtils.placeholder}.cloud.coveo.com`,
    [ENVIRONMENT.production]: `https://platform${RegionUtils.placeholder}.cloud.coveo.com`,
    [ENVIRONMENT.hipaa]: 'https://platformhipaa.cloud.coveo.com',
};

export const COMMERCE_HUB_BASE_URL = {
    [ENVIRONMENT.local]: 'https://local.stratus.coveo.com:8481',
    [ENVIRONMENT.dev]: `https://commercedev${RegionUtils.placeholder}.cloud.coveo.com`,
    [ENVIRONMENT.stg]: `https://commercestg${RegionUtils.placeholder}.cloud.coveo.com`,
    [ENVIRONMENT.production]: `https://commerce${RegionUtils.placeholder}.cloud.coveo.com`,
    [ENVIRONMENT.hipaa]: 'https://commercehipaa.cloud.coveo.com',
};

export const KNOWLEDGE_HUB_BASE_URL = {
    [ENVIRONMENT.local]: 'https://local.stratus.coveo.com:8481',
    [ENVIRONMENT.dev]: `https://knowledgedev${RegionUtils.placeholder}.cloud.coveo.com`,
    [ENVIRONMENT.stg]: `https://knowledgestg${RegionUtils.placeholder}.cloud.coveo.com`,
    [ENVIRONMENT.production]: `https://knowledge${RegionUtils.placeholder}.cloud.coveo.com`,
    [ENVIRONMENT.hipaa]: 'https://knowledgehipaa.cloud.coveo.com',
};

const CLOUD_SEARCH_BASE_URL = {
    [ENVIRONMENT.local]: 'https://local.stratus.coveo.com:8481',
    [ENVIRONMENT.dev]: `https://searchdev${RegionUtils.placeholder}.cloud.coveo.com`,
    [ENVIRONMENT.stg]: `https://searchstg${RegionUtils.placeholder}.cloud.coveo.com`,
    [ENVIRONMENT.production]: `https://search${RegionUtils.placeholder}.cloud.coveo.com`,
    [ENVIRONMENT.hipaa]: 'https://searchhipaa.cloud.coveo.com',
};

export const USAGE_ANALYTICS_ALT_DOMAINS = {
    [ENVIRONMENT.local]: [],
    [ENVIRONMENT.dev]: [
        `https://platformdev${RegionUtils.placeholder}0.cloud.coveo.com`,
        `https://platformdev${RegionUtils.placeholder}1.cloud.coveo.com`,
    ],
    [ENVIRONMENT.stg]: [
        `https://platformstg${RegionUtils.placeholder}0.cloud.coveo.com`,
        `https://platformstg${RegionUtils.placeholder}1.cloud.coveo.com`,
    ],
    [ENVIRONMENT.production]: [
        `https://platform${RegionUtils.placeholder}0.cloud.coveo.com`,
        `https://platform${RegionUtils.placeholder}1.cloud.coveo.com`,
    ],
    [ENVIRONMENT.hipaa]: [
        `https://platformhipaa${RegionUtils.placeholder}0.cloud.coveo.com`,
        `https://platformhipaa${RegionUtils.placeholder}1.cloud.coveo.com`,
    ],
};

const API_ENDPOINT_URL = {
    [ENVIRONMENT.dev]: `https://apidev${RegionUtils.placeholder}.cloud.coveo.com`,
    [ENVIRONMENT.stg]: `https://apistg${RegionUtils.placeholder}.cloud.coveo.com`,
    [ENVIRONMENT.production]: `https://api${RegionUtils.placeholder}.cloud.coveo.com`,
    [ENVIRONMENT.hipaa]: 'https://apihipaa.cloud.coveo.com',
};

const ONLINE_HELP_BASE_URL = {
    [ENVIRONMENT.local]: 'https://docs.coveo.com/',
    [ENVIRONMENT.dev]: 'https://docs.coveo.com/',
    [ENVIRONMENT.stg]: 'https://docs.coveo.com/',
    [ENVIRONMENT.production]: 'https://docs.coveo.com/',
    [ENVIRONMENT.hipaa]: 'https://docs.coveo.com/',
};

/**
 * Status page beautiful urls (http only)
 */
export const STATUS_PAGE_URL = {
    [ENVIRONMENT.local]: 'https://status.cloud.coveo.com',
    [ENVIRONMENT.dev]: 'https://status.cloud.coveo.com',
    [ENVIRONMENT.stg]: 'https://status.cloud.coveo.com',
    [ENVIRONMENT.production]: 'https://status.cloud.coveo.com',
    [ENVIRONMENT.hipaa]: 'https://status-hipaa.cloud.coveo.com',
};

/**
 * Status page https api urls (https)
 */
export const STATUS_PAGE_API_URL = {
    [ENVIRONMENT.local]: 'https://jgdmnlhfjdlf.statuspage.io',
    [ENVIRONMENT.dev]: 'https://jgdmnlhfjdlf.statuspage.io',
    [ENVIRONMENT.stg]: 'https://jgdmnlhfjdlf.statuspage.io',
    [ENVIRONMENT.production]: 'https://jgdmnlhfjdlf.statuspage.io',
    [ENVIRONMENT.hipaa]: 'https://y7lrnsy2bjgf.statuspage.io',
};

const WHATS_NEW_URL = {
    [ENVIRONMENT.local]: 'https://docs.coveo.com/en/1756/',
    [ENVIRONMENT.dev]: 'https://docs.coveo.com/en/1756/',
    [ENVIRONMENT.stg]: 'https://docs.coveo.com/en/1756/',
    [ENVIRONMENT.production]: 'https://docs.coveo.com/en/1756/',
    [ENVIRONMENT.hipaa]: 'https://docs.coveo.com/en/1756/',
};

const LAUNCHDARKLY_SEARCH_PAGES_CLIENT_ID = {
    [ENVIRONMENT.local]: '5cf6c0ec5c118807582dd671',
    [ENVIRONMENT.dev]: '5cf6c0ec5c118807582dd671',
    [ENVIRONMENT.stg]: '65c4d8586d4afa0f9b5a0de5',
    [ENVIRONMENT.production]: '5cfe85e0114bcf075bec1347',
    [ENVIRONMENT.hipaa]: '5cfe8d3ba2f8290765e10fa7',
};

const LAUNCHDARKLY_PLATFORM_CLIENT_ID = {
    [ENVIRONMENT.local]: '5d84c9b6af41f807ada78957',
    [ENVIRONMENT.dev]: '5d84c9b6af41f807ada78957',
    [ENVIRONMENT.stg]: '65c3b83ebc20eb0f99e607fe',
    [ENVIRONMENT.production]: '5d9f2a5e80169408b4d18583',
    [ENVIRONMENT.hipaa]: '5d9f394670ef5008ad90b9cf',
};

const LAUNCHDARKLY_CUSTOMER_SERVICE_CLIENT_ID = {
    [ENVIRONMENT.local]: '6536a75bc0abbe12389ced46',
    [ENVIRONMENT.dev]: '6536a75bc0abbe12389ced46',
    [ENVIRONMENT.stg]: '65c3a874c563ef1012f6f52f',
    [ENVIRONMENT.production]: '5f1828624aa0150bec5cbaec',
    [ENVIRONMENT.hipaa]: '5f1837199933dd0b3d5e9954',
};

const LAUNCHDARKLY_SEARCH_INTERFACE_CLIENT_ID = {
    [ENVIRONMENT.local]: '63862c5da16fb2116ef0a1f6',
    [ENVIRONMENT.dev]: '63862c5da16fb2116ef0a1f6',
    [ENVIRONMENT.stg]: '6283df38ac5bdb156ab09867',
    [ENVIRONMENT.production]: '60ca6dc322e5b90c7b536ad4',
    [ENVIRONMENT.hipaa]: '61e17aedf347fb16d5e7e6d2',
};

const LAUNCHDARKLY_COMMERCE_SERVICE_CLIENT_ID = {
    [ENVIRONMENT.local]: '63e167849d09eb13b6ef5d73',
    [ENVIRONMENT.dev]: '63e167849d09eb13b6ef5d73',
    [ENVIRONMENT.stg]: '63e2afcaee61091355e33a3b',
    [ENVIRONMENT.production]: '63e3fceb9ad827135ed1d95c',
    [ENVIRONMENT.hipaa]: '63e41796d82dcc1344245671',
};

const HELP_WIDGET_ID = '3b5b3092-300e-45fd-80a4-35831bcbe0d5';

const HELP_WIDGET_ORG = 'coveosearch';

/**
 * Documentation base hosts
 */

export const DocumentationHostsUrls: Record<DOC_HOSTS, string> = {
    [DOC_HOSTS.DOCS]: 'https://docs.coveo.com',
    [DOC_HOSTS.COMMUNITY]: 'https://community.coveo.com',
};

export const AccessTokenScopes = {
    Full: 'full',
};

const origin =
    window.location.protocol +
    '//' +
    window.location.hostname +
    (window.location.port ? ':' + window.location.port : '');

const region = RegionUtils.extractRegion(origin);

const getEnvFromDocumentURL = (): string => {
    const UI_HOSTNAMES = {
        [ENVIRONMENT.hipaa]: [
            'hipaa',
            'commercehipaa.cloud.coveo.com',
            'knowledgehipaa.cloud.coveo.com',
            'platformhipaa.cloud.coveo.com',
        ],
        [ENVIRONMENT.production]: [
            'production',
            `commerce${RegionUtils.placeholder}.cloud.coveo.com`,
            `knowledge${RegionUtils.placeholder}.cloud.coveo.com`,
            `platform${RegionUtils.placeholder}.cloud.coveo.com`,
        ],
        [ENVIRONMENT.stg]: [
            'stg',
            `commercestg${RegionUtils.placeholder}.cloud.coveo.com`,
            `knowledgestg${RegionUtils.placeholder}.cloud.coveo.com`,
            `platformstg${RegionUtils.placeholder}.cloud.coveo.com`,
        ],
        [ENVIRONMENT.dev]: [
            'dev',
            `commercedev${RegionUtils.placeholder}.cloud.coveo.com`,
            `knowledgedev${RegionUtils.placeholder}.cloud.coveo.com`,
            `platformdev${RegionUtils.placeholder}.cloud.coveo.com`,
            // docs website
            'localhost:3000',
            'docs.admin-ui.coveo.com',
        ],
    };

    const parameters = new URLSearchParams(window.location.search);

    // look for the env in the querystring Should only be used when debugging
    for (const currentEnvironment in UI_HOSTNAMES) {
        if (Object.prototype.hasOwnProperty.call(UI_HOSTNAMES, currentEnvironment)) {
            for (let i = 0; i < UI_HOSTNAMES[currentEnvironment].length; i++) {
                if (parameters.get(UI_HOSTNAMES[currentEnvironment][i]) != null) {
                    return currentEnvironment;
                }
            }
        }
    }

    // real lookup of the environment
    for (const currentEnvironment in UI_HOSTNAMES) {
        if (Object.prototype.hasOwnProperty.call(UI_HOSTNAMES, currentEnvironment)) {
            for (let i = 0; i < UI_HOSTNAMES[currentEnvironment].length; i++) {
                const isOriginUs = origin.includes('-us') && region === Region.US;
                if (
                    origin.includes(RegionUtils.injectRegion(UI_HOSTNAMES[currentEnvironment][i], region, isOriginUs))
                ) {
                    return currentEnvironment;
                }
            }
        }
    }

    return ENVIRONMENT.local;
};

const getServiceUrl = ((queryParam: string, defaultEndpoint: string | string[]): string | string[] => {
    const parameters = new URLSearchParams(window.location.search);
    const paramValue = parameters.get(queryParam);
    const allowedHosts: Array<string | RegExp> = [/\.cloud\.coveo\.com$/];
    if (env === Environment.dev) {
        allowedHosts.push('localhost');
        allowedHosts.push(/\.corp\.coveo\.com$/);
    }

    // We need to validate that the param is a URL and controlled by Coveo,
    // otherwise it can be used for XSS or to proxy requests and steal data
    if (
        paramValue &&
        validator.isURL(paramValue, {
            require_valid_protocol: true,
            host_whitelist: allowedHosts,
        })
    ) {
        const anchor = document.createElement('a');
        anchor.href = paramValue;
        return `${anchor.protocol}//${anchor.host}`;
    }

    if (typeof defaultEndpoint === 'string') {
        return RegionUtils.injectRegion(defaultEndpoint, region);
    } else if (Array.isArray(defaultEndpoint)) {
        return defaultEndpoint.map((endpoint) => RegionUtils.injectRegion(endpoint, region));
    } else {
        return '';
    }
}) as {
    (queryParam: string, defaultEndpoint: string): string;
    (queryParam: string, defaultEndpoint: string[]): string[];
};

const dockerEnv = '$$_ENV_$$';
const env: string = dockerEnv.indexOf('$') === -1 ? dockerEnv : getEnvFromDocumentURL();
const platformUrl = getServiceUrl('platform', env === ENVIRONMENT.local ? origin : CLOUD_PLATFORM_BASE_URL[env]);
const commerceUrl = getServiceUrl('commerce', env === ENVIRONMENT.local ? origin : COMMERCE_HUB_BASE_URL[env]);
const knowledgeUrl = getServiceUrl('knowledge', env === ENVIRONMENT.local ? origin : KNOWLEDGE_HUB_BASE_URL[env]);

const searchUrl = getServiceUrl('search', env === ENVIRONMENT.local ? origin : CLOUD_SEARCH_BASE_URL[env]);

let uaAlts = getServiceUrl('uaalts', [] as string[]);
if (platformUrl === RegionUtils.injectRegion(CLOUD_PLATFORM_BASE_URL[env], region) && uaAlts.length === 0) {
    uaAlts = USAGE_ANALYTICS_ALT_DOMAINS[env].map((url) => RegionUtils.injectRegion(url, region));
}

const applicationIdMap = {
    'commerce-hub': 'CommerceAdmin',
    'admin-ui': 'JSAdmin',
    'knowledge-hub': 'KnowledgeAdmin',
    docs: 'AdminUIDocs',
};

const envShort: string = ENVIRONMENT_SHORT[env] || env;

export const Config = {
    app: __APP_NAME__,
    env,
    envShort,
    CloudPlatform: {
        // eslint-disable-next-line no-underscore-dangle
        clientId: applicationIdMap[__APP_NAME__], // you can set 'JSAdmin3' to test a stricter setup equivalent to what we have in prod
        responseType: 'token',
        url: platformUrl,
        commerceUrl,
        knowledgeUrl,
        region,
        authorizePath: '/oauth/authorize',
        getUrlForRegion: (newRegion: Region) =>
            RegionUtils.injectRegion(CLOUD_PLATFORM_BASE_URL[Config.env], newRegion),
    },
    CloudPlatformDocumentation: {
        url: platformUrl + '/docs',
        helpWidget: `https://platform.cloud.coveo.com/rest/organizations/${HELP_WIDGET_ORG}/ipxinterface/v1/interfaces/${HELP_WIDGET_ID}/loader`,
        helpWidgetApiKey: 'xx6a436255-bd33-4a4b-916d-851d37ae0347',
    },
    SearchApi: {
        url: getServiceUrl('search-api', platformUrl) + '/rest/search',
    },
    Search: {
        url: searchUrl,
        getSearchPageViewUrl: ({orgId, id}) =>
            `${searchUrl}/rest/organizations/${sanitizeOrganizationId(
                orgId,
            )}/searchinterfaces/${sanitizeHostedSearchPageId(id)}/html`,
        getClassicSearchPageViewUrl: ({orgId, name}) =>
            `${searchUrl}/pages/${sanitizeOrganizationId(orgId)}/${sanitizeClassicHostedSearchPageName(name)}`,
        getInProductExperienceEditUrl: ({orgId, id}) =>
            `${searchUrl}/rest/organizations/${sanitizeOrganizationId(
                orgId,
            )}/ipxinterface/v1/interfaces/${sanitizeHostedSearchPageId(id)}/edit`,
    },
    Catalog: {
        url: getServiceUrl('catalog', platformUrl),
    },
    UsageAnalytics: {
        url: getServiceUrl('ua', platformUrl) + '/rest/ua/v15',
        alts: (Array.isArray(uaAlts) ? uaAlts : [uaAlts]).map((url) => `${url}/rest/ua/v15`),
        envEnabled: {
            [ENVIRONMENT.local]: false,
            [ENVIRONMENT.dev]: true,
            [ENVIRONMENT.stg]: true,
            [ENVIRONMENT.production]: true,
            [ENVIRONMENT.hipaa]: true,
        },
    },
    LogBrowser: {
        url: getServiceUrl('log-browser', API_ENDPOINT_URL[env]),
    },
    SourcesPush: {
        url: getServiceUrl('push', API_ENDPOINT_URL[env]) + '/push/v1',
    },
    OnlineHelp: {
        url: getServiceUrl('onlinehelp', ONLINE_HELP_BASE_URL[env]),
    },
    WhatsNew: {
        url: WHATS_NEW_URL[env],
    },
    LaunchDarkly: {
        SearchPages: {
            clientID: LAUNCHDARKLY_SEARCH_PAGES_CLIENT_ID[env],
        },
        Platform: {
            clientID: LAUNCHDARKLY_PLATFORM_CLIENT_ID[env],
        },
        CustomerService: {
            clientID: LAUNCHDARKLY_CUSTOMER_SERVICE_CLIENT_ID[env],
        },
        SearchInterface: {
            clientID: LAUNCHDARKLY_SEARCH_INTERFACE_CLIENT_ID[env],
        },
        CommerceService: {
            clientID: LAUNCHDARKLY_COMMERCE_SERVICE_CLIENT_ID[env],
        },
    },
};
